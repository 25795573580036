import { makeStyles } from '@material-ui/core'

import { Theme } from 'src/theme'
import { TYPOGRAPHY } from 'src/theme/typography'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: theme.spacing(5, 0),
      // "zIndex" is necessary
      zIndex: 1,
      position: 'fixed',
      bottom: 0,
      width: '100%',
      background: theme.gradients.primary,

      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(9, 0),
      },

      '& .MuiContainer-root': {
        padding: theme.spacing(0, 5),
      },
    },
    basketText: {
      fontWeight: TYPOGRAPHY.fontWeightBold,
    },
  }),
  { name: 'Basket' },
)

export default useStyles
