import type { FC } from 'react'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'

import { TestIds } from 'src/testIds'
import { tArray } from 'src/utils'
import Page from 'src/components/Page/Page'

import { LegalDocument } from '../../components/LegalDocument/LegalDocument'

const LegalNoticeTestIds = TestIds.views.legalNotice

const LegalNoticeView: FC = () => {
  const { t } = useTranslation()

  return (
    <Page
      data-test-id={LegalNoticeTestIds.pageWrapper}
      title={t('Views.LegalNotice.title')}
    >
      <LegalDocument title={t('Views.LegalNotice.title')}>
        <Typography variant="h2" gutterBottom>
          {t('Views.LegalNotice.mandatoryInformation.header')}
        </Typography>
        <Typography paragraph>
          {tArray(
            t,
            'Views.LegalNotice.mandatoryInformation.addressLines',
          )?.map((text, index) => (
            <Fragment key={index}>
              {text}
              <br />
            </Fragment>
          ))}
        </Typography>
        <Typography paragraph>
          {tArray(t, 'Views.LegalNotice.mandatoryInformation.contactLines', {
            mailToEnd: '</a>',
            mailToStart: '<a href="mailto:info@hzv.de">',
          })?.map((text, index) => (
            <Fragment key={index}>
              <span dangerouslySetInnerHTML={{ __html: text }} />
              <br />
            </Fragment>
          ))}
        </Typography>
        <Typography paragraph>
          {tArray(t, 'Views.LegalNotice.mandatoryInformation.legalLines')?.map(
            (text, index) => (
              <Fragment key={index}>
                {text}
                <br />
              </Fragment>
            ),
          )}
        </Typography>
        <Typography variant="h2" gutterBottom>
          {t('Views.LegalNotice.legalStatement.header')}
        </Typography>
        <Typography paragraph>
          {tArray(t, 'Views.LegalNotice.legalStatement.paragraphs')?.map(
            (text, index) => (
              <Fragment key={index}>
                {text}
                <br />
                <br />
              </Fragment>
            ),
          )}
        </Typography>
      </LegalDocument>
    </Page>
  )
}

export default LegalNoticeView
