import type { FC } from 'react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Typography } from '@material-ui/core'

import { TestIds } from 'src/testIds'
import useContracts from 'src/hooks/useContracts'
import Basket from 'src/components/Basket/Basket'
import ContactStripe from 'src/components/ContactStripe/ContactStripe'
import ContentStripe from 'src/components/ContentStripe/ContentStripe'
import ContractList from 'src/components/ContractList/ContractList'
import LoadingScreen from 'src/components/LoadingScreen/LoadingScreen'
import Page from 'src/components/Page/Page'

import useRegions from '../../hooks/useRegions'

import useStyles from './ContractListView.styles'

const ContractListTestIds = TestIds.views.contractList

const ContractListView: FC = () => {
  const { getters: contractGetters, state: contractState } = useContracts()
  const { getters: regionGetters } = useRegions()
  const { t } = useTranslation()
  const classes = useStyles()
  const regionSlug = contractState.contractList.currentRegion ?? ''
  const selectedContracts =
    contractGetters.getSubSetOfSelectedAndFilteredContracts()
  const regionalContracts = contractGetters.getContracts({
    by: 'regionSlug',
    value: regionSlug,
  })

  const region = regionGetters.getRegion({
    by: 'slug',
    value: regionSlug,
  })

  return (
    <Page
      className={classes.root}
      data-test-id={ContractListTestIds.pageWrapper}
      title={t('Views.ContractList.title')}
    >
      {contractState.loading && <LoadingScreen />}

      {!contractState.loading && !regionalContracts?.length && (
        <ContentStripe>
          <Grid container>
            <Grid item xs={12}>
              <Box m={9}>
                <Typography align="center" variant="h2">
                  {t('Views.ContractList.noContracts')}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </ContentStripe>
      )}

      {!contractState.loading && !!regionalContracts?.length && (
        <ContractList currentRegion={region} />
      )}

      {!contractState.loading && !!selectedContracts.length && (
        <Basket numberOfContracts={selectedContracts.length} />
      )}

      <ContactStripe
        regionPhone={region?.phoneContract}
        serviceHoursRow1={region?.serviceHoursRow1}
        serviceHoursRow2={region?.serviceHoursRow2}
      />
    </Page>
  )
}

export default ContractListView
