import { makeStyles } from '@material-ui/core'

import type { Theme } from 'src/theme'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      marginBottom: theme.settings.topBarHeight,
    },
    bold: {
      fontWeight: theme.typography.fontWeightBold as number,
    },
    blockItem: {
      margin: '0 auto',
      display: 'block',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      gap: 20,

      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },
  }),

  { name: 'HomeView' },
)

export default useStyles
