import { makeStyles } from '@material-ui/core'

import { Theme } from 'src/theme'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    contractFormWrapper: {
      '&.MuiGrid-item': {
        [theme.breakpoints.down('xs')]: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
  }),

  { name: 'ContractFormView' },
)

export default useStyles
