import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from '@material-ui/lab/Skeleton'

import { TestIds } from 'src/testIds'
import {
  AdditionalPrivacyPolicy,
  getAdditionalPrivacyPolicy,
} from 'src/services/api/api'
import { Markdown } from 'src/components/Markdown/Markdown'
import Page from 'src/components/Page/Page'

import { LegalDocument } from '../../components/LegalDocument/LegalDocument'

const PrivacyTestIds = TestIds.views.privacy

const PrivacyView: FC = () => {
  const { t } = useTranslation()

  const [additionalPrivacyPolicy, setAdditionalPrivacyPolicy] =
    useState<AdditionalPrivacyPolicy | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: _additionalPrivacyPolicy } =
          await getAdditionalPrivacyPolicy()

        setAdditionalPrivacyPolicy(_additionalPrivacyPolicy)
      } catch (error) {
        setAdditionalPrivacyPolicy(null)
      }
    }
    fetchData()
  }, [t])

  return (
    <Page
      data-test-id={PrivacyTestIds.pageWrapper}
      title={t('Views.Privacy.title')}
    >
      <LegalDocument title={t('Views.Privacy.header')}>
        {!additionalPrivacyPolicy ? (
          <Skeleton variant="text" width="100%" />
        ) : (
          <Markdown>
            {additionalPrivacyPolicy.additionalDataPrivacyInfo}
          </Markdown>
        )}
      </LegalDocument>
    </Page>
  )
}

export default PrivacyView
