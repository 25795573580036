import type { FC } from 'react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Grid } from '@material-ui/core'

import { TestIds } from 'src/testIds'
import useRegions from 'src/hooks/useRegions'
import { AspectRatioImg } from 'src/components/AspectRatioImg'
import ContactStripe from 'src/components/ContactStripe/ContactStripe'
import ContentStripe from 'src/components/ContentStripe/ContentStripe'
import MembershipForm from 'src/components/MembershipForm/MembershipForm'
import Page from 'src/components/Page/Page'

import useStyles from './MembershipFormView.styles'

const membershipFormTestIds = TestIds.views.membershipForm

const MembershipFormView: FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const params = useParams<{ regionSlug: string }>()
  const { getters: regionGetters } = useRegions()
  const region = regionGetters.getRegion({
    by: 'slug',
    value: params.regionSlug || '',
  })

  return (
    <Page
      className={classes.root}
      data-test-id={membershipFormTestIds.pageWrapper}
      title={t('Views.MembershipForm.title')}
    >
      <ContentStripe>
        <Grid
          alignItems="center"
          container
          justifyContent="center"
          spacing={1}
          direction={'column'}
        >
          {region?.regionalAssociationLogoUrl && (
            <Grid
              item
              style={{
                maxWidth: 505, // magic number so that it nearly matches the height declared in screen design
                width: '100%',
              }}
            >
              <AspectRatioImg
                src={region.regionalAssociationLogoUrl}
                alt={'Landesverband logo'}
              />
            </Grid>
          )}
        </Grid>
      </ContentStripe>

      <ContentStripe>
        <Grid container spacing={4}>
          <Grid item xs={12} classes={{ item: classes.contractFormWrapper }}>
            <MembershipForm />
          </Grid>
        </Grid>
      </ContentStripe>

      <ContactStripe
        regionPhone={region?.phoneMembership}
        serviceHoursRow1={region?.serviceHoursRow1}
        serviceHoursRow2={region?.serviceHoursRow2}
      />
    </Page>
  )
}

export default MembershipFormView
