import type { FC } from 'react'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@material-ui/core'

import { TestIds } from 'src/testIds'
import { tArray } from 'src/utils'
import { LegalDocument } from 'src/components/LegalDocument/LegalDocument'
import Page from 'src/components/Page/Page'

const GtcTestIds = TestIds.views.gtc

const GtcView: FC = () => {
  const { t } = useTranslation()

  return (
    <Page data-test-id={GtcTestIds.pageWrapper} title={t('Views.Gtc.title')}>
      <LegalDocument title={t('Views.Gtc.titleFull')}>
        <Typography variant="h2" color="primary" gutterBottom>
          {t('Views.Gtc.header')}
        </Typography>

        {tArray(t, 'Views.Gtc.paragraphs', {
          mailToEnd: '</a>',
          mailToStart: '<a href="mailto:info@hzv.de">',
        })?.map((paragraph, paragraphNo) => (
          <Fragment key={paragraphNo}>
            <Typography variant="h3" color="secondary" gutterBottom>
              § {paragraphNo + 1} {paragraph.title}
            </Typography>

            <Box px={4} mb={2}>
              <Typography
                component="ol"
                style={{ listStyle: paragraph.listStyle }}
              >
                {paragraph.subParagraphs.map((text: string, index: number) => (
                  <Typography
                    gutterBottom
                    component="li"
                    key={text}
                    dangerouslySetInnerHTML={{ __html: text }}
                  />
                ))}
              </Typography>
            </Box>
          </Fragment>
        ))}
      </LegalDocument>
    </Page>
  )
}

export default GtcView
