import React, { createRef, FC, useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import {
  Box,
  Checkbox,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  Hidden,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import {
  Close as CloseIcon,
  Error as ErrorIcon,
  Info as InfoIcon,
} from '@material-ui/icons'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import clsx from 'clsx'
import { format, sub } from 'date-fns'
import type { FormikErrors, FormikProps } from 'formik'
import { FieldArray, getIn } from 'formik'
import i18n from 'i18next'
import * as Yup from 'yup'
import { ValidationError } from 'yup'

import {
  AdditionalAttribute,
  Qualification,
  Region,
  SupportedSoftware,
} from 'src/types'
import { singleSelection } from 'src/utils/cacheValidation'
import { isPDFValid, PDF_FILE_TYPE } from 'src/utils/isPDFValid'
import {
  getMatrixDisplayColumnSettings,
  getQualifications,
  isQualificationSelected,
  QualificationMatrix,
} from 'src/utils/qualifications'
import useContracts from 'src/hooks/useContracts'
import Button from 'src/components/Button/Button'
import { PanelSubmitRow } from 'src/components/ContractForm/PanelSubmitRow/PanelSubmitRow'
import { KeyboardDatePicker } from 'src/components/DatePicker/KeyboardDatePicker'
import ErrorDialog from 'src/components/ErrorDialog/ErrorDialog'
import Markdown from 'src/components/Markdown/Markdown'

import { customRegExp } from '../../../utils/form.utils'
import { ContractFormType } from '../ContractForm'
import {
  checkLanr,
  validateFirstName,
  validateLanrId,
  validateLastName,
} from '../validationSchema'

import useStyles from '../ContractForm.styles'

const today = new Date()
const maxDOB = format(sub(today, { years: 18 }), 'yyyy-MM-dd')
const minDOB = format(sub(today, { years: 100 }), 'yyyy-MM-dd')

export interface VerahCoworker {
  activeSince: any
  birthName: string
  dateOfBirth: any
  firstName: string
  lastName: string
  weeklyHours: string
  weeklyHoursNew: string
  weeklyHoursChangedSince: any
}

export interface EmployerData {
  employerFirstName: string
  employerLastName: string
  employerLanrId: string
  employerHaevgId?: string
  employerFax: string
  employerEmail: string
}

export interface PanelParticipationReqType {
  employmentType: string
  employerName: string
  employerConsent: boolean
  authorisedByTheKvAdmissionsCommittee: boolean
  employerData: EmployerData | undefined
  dmpAsthma: boolean
  dmpCopd: boolean
  dmpDiabetes: boolean
  dmpKhk: boolean
  dmpCertificates: []
  softwareId: string
  verahCoworkers: VerahCoworker[]
  certificates: []
  qualificationIds: string[]
  additionalAttributeIds: string[]
  // The following attributes depend on the selected qualifications
  // The length of the arrays must be equal to the length of the qualifications
  kimAddresses: (string | null)[]
  qualificationAnswers: (string | null)[]
  qualificationCustomAnswers: (string | null)[]
}

interface QualificationMatrixDisplaySettings {
  providerAffiliatePartnerId: boolean
  providerDeviceAssociationId: boolean
  providerPersonalId: boolean
}

/* eslint-disable sort-keys-fix/sort-keys-fix */
export const initialValues: PanelParticipationReqType = {
  employmentType: '',
  employerName: '',
  employerData: {
    employerFirstName: '',
    employerLastName: '',
    employerLanrId: '',
    employerFax: '',
    employerEmail: '',
  },
  employerConsent: false,
  authorisedByTheKvAdmissionsCommittee: false,
  dmpAsthma: false,
  dmpCopd: false,
  dmpDiabetes: false,
  dmpKhk: false,
  dmpCertificates: [],
  softwareId: '',
  verahCoworkers: [],
  certificates: [],
  qualificationIds: [],
  kimAddresses: [],
  qualificationAnswers: [],
  qualificationCustomAnswers: [],
  additionalAttributeIds: [],
}

type DmpType = {
  name: 'dmpAsthma' | 'dmpCopd' | 'dmpDiabetes' | 'dmpKhk'
  text: string
  value: number
}

const employerDataSchema = Yup.object().shape({
  employerFirstName: validateFirstName,
  employerLastName: validateLastName,
  employerLanrId: validateLanrId.test(
    'Validate LANR',
    i18n.t('ContractForm.Fields.PersonalData.LanrId.valid'),
    (lanrInput) => checkLanr(lanrInput),
  ),
  employerHaevgId: Yup.string()
    .max(9, i18n.t('ContractForm.Fields.PersonalData.HaevgId.max'))
    .matches(/^\d+$/, i18n.t('ContractForm.Fields.PersonalData.HaevgId.type'))
    .nullable(),
  employerFax: Yup.string()
    .required(i18n.t('ContractForm.Fields.BsData.BsFax.required'))
    .min(7, i18n.t('ContractForm.Fields.BsData.BsFax.format'))
    .matches(
      customRegExp.phone,
      i18n.t('ContractForm.Fields.BsData.BsFax.format'),
    )
    .max(50, i18n.t('General.Form.maxLength', { length: 50 })),
  employerEmail: Yup.string()
    .email(i18n.t('ContractForm.Fields.BsData.BsEmail.format'))
    .required(i18n.t('ContractForm.Fields.BsData.BsEmail.required')),
})

const maxFileSize = 20000 * 1024 // 20 MB

const dynamicallyRequiredStringSchema = Yup.string()
  .nullable()
  .test('required', i18n.t('General.Form.required'), (value) => {
    // The "required" status of this field depends on the qualification and the selected qualificationIds.
    // Therefore, we handle the "required" state of the field via the `null` value.
    // --> If the value is `null`, the field is NOT required.
    return value === null || (typeof value === 'string' && value.length > 0)
  })

export const validationSchema = (
  additionalAttributes: AdditionalAttribute[],
  qualifications: Qualification[],
  verahHoursOptions: string[],
  contractApplicationRequiresEmployerAuthorisation: boolean,
) =>
  Yup.object()
    .shape({
      employmentType: Yup.number()
        .oneOf([1, 2, 3])
        .required(i18n.t('ContractForm.Fields.PrData.EmploymentType.required')),
      employerName: Yup.string()
        .max(255, i18n.t('General.Form.maxLength', { length: 255 }))
        .when('employmentType', {
          is: 2,
          then: Yup.string().required(i18n.t('General.Form.required')),
        }),
      employerConsent: Yup.boolean().when('employmentType', {
        is: (employmentType: number) =>
          (employmentType === 2 || employmentType === 3) &&
          !contractApplicationRequiresEmployerAuthorisation,
        then: Yup.boolean()
          .oneOf([true])
          .required(i18n.t('General.Form.required')),
      }),
      authorisedByTheKvAdmissionsCommittee: Yup.boolean().when(
        'employmentType',
        {
          is: (employmentType: number) =>
            employmentType === 2 || employmentType === 3,
          then: Yup.boolean()
            .oneOf([true])
            .required(i18n.t('General.Form.required')),
        },
      ),
      employerData: Yup.object().when('employmentType', {
        is: (employmentType: number) =>
          contractApplicationRequiresEmployerAuthorisation &&
          (employmentType === 2 || employmentType === 3),
        then: employerDataSchema,
      }),
      verahCoworkers: Yup.array().of(
        Yup.object().shape({
          firstName: validateFirstName,
          lastName: validateLastName,
          birthName: Yup.string().max(
            100,
            i18n.t('General.Form.maxLength', { length: 100 }),
          ),
          dateOfBirth: Yup.date()
            .typeError(i18n.t('ContractForm.Fields.PrData.DateOfBirth.invalid'))
            .nullable()
            .max(maxDOB, i18n.t('ContractForm.Fields.PrData.DateOfBirth.max'))
            .required(
              i18n.t('ContractForm.Fields.PrData.DateOfBirth.required'),
            ),
          activeSince: Yup.date()
            .typeError(i18n.t('ContractForm.Fields.PrData.ActiveSince.invalid'))
            .nullable()
            .required(
              i18n.t('ContractForm.Fields.PrData.ActiveSince.required'),
            ),
          weeklyHours: Yup.string()
            .transform((current) => singleSelection(verahHoursOptions, current))
            .required(
              i18n.t('ContractForm.Fields.PrData.WeeklyHours.required'),
            ),
          weeklyHoursNew: Yup.string().transform((current) =>
            singleSelection(verahHoursOptions, current),
          ),
          weeklyHoursChangedSince: Yup.date()
            .typeError(
              i18n.t(
                'ContractForm.Fields.PrData.WeeklyHoursChangedSince.invalid',
              ),
            )
            .nullable(),
        }),
      ),
      qualificationIds: Yup.array()
        .of(Yup.string())
        // we need to transform into empty array as in submit form a "cast" if executed
        .transform((current) => (current ? current : [])),
      kimAddresses: Yup.array().of(
        dynamicallyRequiredStringSchema
          .email(
            i18n.t('ContractForm.Fields.PrData.qualificationIds.invalidEmail'),
          )
          .matches(
            /\.telematik$/,
            i18n.t('ContractForm.Fields.PrData.qualificationIds.invalidKim'),
          ),
      ),
      qualificationAnswers: Yup.array().of(dynamicallyRequiredStringSchema),
      qualificationCustomAnswers: Yup.array().of(
        dynamicallyRequiredStringSchema,
      ),
      additionalAttributeIds: Yup.array()
        .of(Yup.string())
        // we need to transform into empty array as in submit form a "cast" if executed
        .transform((current) => (current ? current : [])),
      certificates: Yup.array().of(
        Yup.mixed().test(
          'fileSize',
          i18n.t('ContractForm.Fields.PrData.common.uploadError.size'),
          (val) => (val ? (val.size ? val.size < maxFileSize : true) : true),
        ),
      ),
      dmpCertificates: Yup.array()
        .test(
          'maxFileNumber',
          i18n.t('ContractForm.Fields.PrData.common.uploadError.maxFileNumber'),
          (files) => !!(files && files.length <= 10),
        )
        .of(
          Yup.mixed().test(
            'fileSize',
            i18n.t('ContractForm.Fields.PrData.common.uploadError.size'),
            (val) => (val ? (val.size ? val.size < maxFileSize : true) : true),
          ),
        ),
    })
    .test('attr-is-req', 'Required', (obj) => {
      if (additionalAttributes === undefined) {
        return true
      }
      const requiredIds = additionalAttributes
        .filter((item) => item.isRequired)
        .map((item) => item.id.toString())

      const l = requiredIds.filter((id) => {
        if (
          obj.additionalAttributeIds &&
          !obj.additionalAttributeIds.includes(id)
        ) {
          return id
        }
        return false
      })
      const missedIds = l.map((id) => `additionalAttributeIds_${id}`)
      const errorsList = missedIds.map(
        (field) =>
          new ValidationError('Required', null, field) as ValidationError,
      )
      return new ValidationError(errorsList)
    })

/* eslint-enable */

export interface PanelParticipationReqProps {
  className?: string
  formik: FormikProps<ContractFormType>
  region: Region | undefined
  supportedSoftwares: SupportedSoftware[]
  matrices: QualificationMatrix[]
  additionalAttributes: AdditionalAttribute[]
}

export const PanelParticipationReq: FC<PanelParticipationReqProps> = ({
  className,
  formik,
  region,
  supportedSoftwares,
  matrices,
  additionalAttributes,
  ...props
}) => {
  const hasDMPUpload = region?.hasDmpUpload

  const { t } = useTranslation()
  const classes = useStyles()
  const [showVerah, setShowVerah] = useState<boolean>(false)
  const theme = useTheme()
  const mdUp = useMediaQuery(theme.breakpoints.up('md'), {
    noSsr: true,
  })
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  })
  const { state: contractsState } = useContracts()

  const [openModalWith, setOpenModalWith] = useState<{
    content: JSX.Element
    title: string
  } | null>(null)

  const [dialogErrorMessage, openErrorDialogWithMessage] = useState<string>('')

  const verahWeeklyWorkTime = region?.verahWeeklyWorkTime

  const allQualifications = React.useMemo<Qualification[]>(() => {
    return getQualifications(matrices)
  }, [matrices])

  const inputRefsQualifications = React.useMemo(
    () => allQualifications.map(() => createRef<HTMLInputElement>()),
    [allQualifications],
  )

  const inputRefsAdditionalAttributes = React.useMemo(
    () => additionalAttributes.map(() => createRef<HTMLInputElement>()),
    [additionalAttributes],
  )

  const incompatibleContracts = React.useMemo(() => {
    if (formik.values.softwareId && contractsState.contracts.length > 0) {
      const selectedSoftwareId = parseInt(formik.values.softwareId)
      // turn selected contract ids into full objects
      const selectedContracts = contractsState.contracts.filter((contract) =>
        contractsState.contractList.selectedContracts.includes(contract.id),
      )
      // find selected contracts that don't support the selected software
      return selectedContracts
        .filter(
          (contract) =>
            !contract.supportedSoftwares.some(
              (software) => software.id === selectedSoftwareId,
            ),
        )
        .map((contract) => contract.abbreviation)
    } else {
      return []
    }
  }, [
    formik.values.softwareId,
    contractsState.contracts,
    contractsState.contractList.selectedContracts,
  ])

  const inputRefDmpdmpCertificatesUpload = useRef<HTMLInputElement>(null)

  // In order to only show the necessary columns in the qualification matrix
  // we check if all three columns are used with a reduce function. If a column is not
  // used the default value for this column stays at false and wont be shown in the matrix below
  const settingsPerMatrix = React.useMemo<
    QualificationMatrixDisplaySettings[]
  >(() => {
    return matrices.map((matrix) => getMatrixDisplayColumnSettings(matrix.rows))
  }, [matrices])

  const employmentTypeOptions = {
    employedInAPractice: {
      text: t(
        'ContractForm.Fields.PrData.EmploymentType.Options.employedInAPractice',
        { interpolation: { escapeValue: false }, region: region?.name || '' },
      ),
      value: '3',
    },
    employedInMvz: {
      text: t(
        'ContractForm.Fields.PrData.EmploymentType.Options.employedInMvz',
        { region: region?.name || '' },
      ),
      value: '2',
    },
    registeredOfficeOwner: {
      text: t(
        'ContractForm.Fields.PrData.EmploymentType.Options.registeredOfficeOwner',
        { region: region?.name || '' },
      ),
      value: '1',
    },
  }

  const dmpParticipationOptions: DmpType[] = [
    {
      name: 'dmpAsthma',
      text: t('ContractForm.Fields.PrData.DmpParticipation.Options.asthma'),
      value: 1,
    },
    {
      name: 'dmpCopd',
      text: t('ContractForm.Fields.PrData.DmpParticipation.Options.copd'),
      value: 2,
    },
    {
      name: 'dmpDiabetes',
      text: t('ContractForm.Fields.PrData.DmpParticipation.Options.diabetes'),
      value: 3,
    },
    {
      name: 'dmpKhk',
      text: t('ContractForm.Fields.PrData.DmpParticipation.Options.khk'),
      value: 4,
    },
  ]

  const handleShowVerahChange = (checked: boolean) => {
    if (!checked) {
      // reset array
      formik.setValues({
        ...formik.values,
        verahCoworkers: [],
      })
    } else {
      formik.setValues({
        ...formik.values,
        verahCoworkers: [
          {
            activeSince: null,
            birthName: '',
            dateOfBirth: null,
            firstName: '',
            lastName: '',
            weeklyHours: '',
            weeklyHoursChangedSince: null,
            weeklyHoursNew: '',
          },
        ],
      })
    }
  }

  useEffect(() => {
    if (formik.values.verahCoworkers.length > 0) setShowVerah(true)
  }, []) // eslint-disable-line

  return (
    <fieldset className={classes.fieldset} {...props}>
      <Grid container spacing={1}>
        {/* ARBEITSVERHÄLTNIS */}
        <Grid item xs={12}>
          <FormControl
            error={Boolean(
              formik.touched.employmentType && formik.errors.employmentType,
            )}
            required
            variant="outlined"
          >
            <Typography variant="h4" component="h3">
              <Box mb={1}>
                {t('ContractForm.Fields.PrData.EmploymentType.label')}
              </Box>
            </Typography>
            <RadioGroup
              id="employmentType"
              name="employmentType"
              onChange={formik.handleChange}
              value={formik.values.employmentType}
              className={classes.radioGroup}
            >
              {region?.isRegisteredOfficeOwnerOptionActive && (
                <FormControlLabel
                  control={<Radio />}
                  key={employmentTypeOptions.registeredOfficeOwner.value}
                  label={employmentTypeOptions.registeredOfficeOwner.text}
                  value={employmentTypeOptions.registeredOfficeOwner.value}
                />
              )}
              {region?.isEmployedInMvzOptionActive && (
                <FormControlLabel
                  control={<Radio />}
                  key={employmentTypeOptions.employedInMvz.value}
                  label={
                    <span
                      dangerouslySetInnerHTML={{
                        __html: employmentTypeOptions.employedInMvz.text,
                      }}
                    />
                  }
                  value={employmentTypeOptions.employedInMvz.value}
                />
              )}
              {region?.isEmployedInAPracticeOptionActive && (
                <FormControlLabel
                  control={<Radio />}
                  key={employmentTypeOptions.employedInAPractice.value}
                  label={
                    <span
                      dangerouslySetInnerHTML={{
                        __html: employmentTypeOptions.employedInAPractice.text,
                      }}
                    />
                  }
                  value={employmentTypeOptions.employedInAPractice.value}
                />
              )}
            </RadioGroup>

            {formik.errors.employmentType && (
              <FormHelperText>{formik.errors.employmentType}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        {/* ZUSATZINFOS ANGESTELLER ARZT */}
        {(formik.values.employmentType ===
          employmentTypeOptions.employedInMvz.value ||
          formik.values.employmentType ===
            employmentTypeOptions.employedInAPractice.value) && (
          <>
            <Grid item xs={12}>
              <Box my={2}>
                <TextField
                  error={Boolean(
                    formik.touched.employerName && formik.errors.employerName,
                  )}
                  fullWidth
                  inputProps={{ maxLength: 255 }}
                  helperText={
                    formik.touched.employerName && formik.errors.employerName
                  }
                  label={t('ContractForm.Fields.PrData.EmployerName.label')}
                  name="employerName"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  required={
                    formik.values.employmentType ===
                    employmentTypeOptions.employedInMvz.value
                  }
                  value={formik.values.employerName}
                  variant="outlined"
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                error={Boolean(
                  formik.touched.authorisedByTheKvAdmissionsCommittee &&
                    formik.errors.authorisedByTheKvAdmissionsCommittee,
                )}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        formik.values.authorisedByTheKvAdmissionsCommittee
                      }
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      name="authorisedByTheKvAdmissionsCommittee"
                      required
                      value={formik.values.authorisedByTheKvAdmissionsCommittee}
                    />
                  }
                  label={
                    // TODO: Use theme to style error checkbox- and radio-labels
                    <Typography
                      color={
                        formik.touched.authorisedByTheKvAdmissionsCommittee &&
                        formik.errors.authorisedByTheKvAdmissionsCommittee
                          ? 'error'
                          : undefined
                      }
                    >
                      {t(
                        'ContractForm.Fields.PrData.AuthorisedByTheKvAdmissionsCommittee.label',
                      )}{' '}
                      *
                    </Typography>
                  }
                />
              </FormControl>
            </Grid>
            {region?.contractApplicationRequiresEmployerAuthorisation ? (
              <>
                <Grid item xs={12}>
                  <Typography variant="h4" component="h3">
                    <Box fontWeight={'fontWeightBold'}>
                      {t('ContractForm.Fields.PrData.EmployerData.title')}
                    </Box>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant={'body1'}>
                    <Trans
                      i18nKey={
                        'ContractForm.Fields.PrData.EmployerData.description'
                      }
                      components={{
                        bold: <b />,
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="employerData.employerFirstName"
                    error={Boolean(
                      getIn(formik.touched.employerData, 'employerFirstName') &&
                        getIn(formik.errors.employerData, 'employerFirstName'),
                    )}
                    helperText={
                      getIn(formik.touched.employerData, 'employerFirstName') &&
                      getIn(formik.errors.employerData, 'employerFirstName')
                    }
                    label={t(
                      'ContractForm.Fields.PrData.EmployerData.fields.firstName',
                    )}
                    inputProps={{ maxLength: 100 }}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    variant="outlined"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="employerData.employerLastName"
                    error={Boolean(
                      getIn(formik.touched.employerData, 'employerLastName') &&
                        getIn(formik.errors.employerData, 'employerLastName'),
                    )}
                    helperText={
                      getIn(formik.touched.employerData, 'employerLastName') &&
                      getIn(formik.errors.employerData, 'employerLastName')
                    }
                    label={t(
                      'ContractForm.Fields.PrData.EmployerData.fields.lastName',
                    )}
                    inputProps={{ maxLength: 100 }}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    variant="outlined"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="employerData.employerLanrId"
                    error={Boolean(
                      getIn(formik.touched.employerData, 'employerLanrId') &&
                        getIn(formik.errors.employerData, 'employerLanrId'),
                    )}
                    helperText={
                      getIn(formik.touched.employerData, 'employerLanrId') &&
                      getIn(formik.errors.employerData, 'employerLanrId')
                    }
                    label={t(
                      'ContractForm.Fields.PrData.EmployerData.fields.lanrId',
                    )}
                    inputProps={{ maxLength: 100 }}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="employerData.employerHaevgId"
                    error={Boolean(
                      getIn(formik.touched.employerData, 'employerHaevgId') &&
                        getIn(formik.errors.employerData, 'employerHaevgId'),
                    )}
                    helperText={
                      getIn(formik.touched.employerData, 'employerHaevgId') &&
                      getIn(formik.errors.employerData, 'employerHaevgId')
                    }
                    label={t(
                      'ContractForm.Fields.PrData.EmployerData.fields.haevgId',
                    )}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="employerData.employerFax"
                    error={Boolean(
                      getIn(formik.touched.employerData, 'employerFax') &&
                        getIn(formik.errors.employerData, 'employerFax'),
                    )}
                    helperText={
                      getIn(formik.touched.employerData, 'employerFax') &&
                      getIn(formik.errors.employerData, 'employerFax')
                    }
                    label={t(
                      'ContractForm.Fields.PrData.EmployerData.fields.faxNr',
                    )}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    variant="outlined"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="employerData.employerEmail"
                    error={Boolean(
                      getIn(formik.touched.employerData, 'employerEmail') &&
                        getIn(formik.errors.employerData, 'employerEmail'),
                    )}
                    helperText={
                      (getIn(formik.touched.employerData, 'employerEmail') &&
                        getIn(formik.errors.employerData, 'employerEmail')) ||
                      t(
                        'ContractForm.Fields.PrData.EmployerData.fields.email.helpText',
                      )
                    }
                    label={t(
                      'ContractForm.Fields.PrData.EmployerData.fields.email.label',
                    )}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    variant="outlined"
                    fullWidth
                    required
                  />
                </Grid>
              </>
            ) : (
              <Grid item xs={12}>
                <FormControl
                  error={Boolean(
                    formik.touched.employerConsent &&
                      formik.errors.employerConsent,
                  )}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.employerConsent}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        name="employerConsent"
                        required
                        value={formik.values.employerConsent}
                      />
                    }
                    label={
                      // TODO: Use theme to style error checkbox- and radio-labels
                      <Typography
                        color={
                          formik.touched.employerConsent &&
                          formik.errors.employerConsent
                            ? 'error'
                            : undefined
                        }
                      >
                        {t('ContractForm.Fields.PrData.EmployerConsent.label')}{' '}
                        *
                      </Typography>
                    }
                  />
                </FormControl>
              </Grid>
            )}
          </>
        )}

        {/* Teilnahme an folgenden DMP */}
        <Grid item xs={12}>
          <FormControl variant="outlined">
            <Typography variant="h4" component="h3">
              <Box fontWeight={'fontWeightBold'} mt={2}>
                {t('ContractForm.Fields.PrData.DmpParticipation.label1')}
                <br />
                {t('ContractForm.Fields.PrData.DmpParticipation.label2')}
              </Box>
            </Typography>
            <FormGroup>
              {dmpParticipationOptions.map((option) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values[option.name]}
                      name={option.name}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                  }
                  key={option.name}
                  label={option.text}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Grid>
        {/* DMP UPLOAD */}
        {hasDMPUpload && (
          <Grid item xs={12} md={12}>
            <Collapse
              in={dmpParticipationOptions.some(
                ({ name }) => !!formik.values[name],
              )}
              onExiting={() => {
                inputRefDmpdmpCertificatesUpload.current!.value = ''
                formik.setFieldValue('dmpCertificates', [])
              }}
            >
              {
                <>
                  <Typography gutterBottom>
                    {t(
                      'ContractForm.Fields.PrData.DmpParticipation.uploadDmpCertificate',
                    )}
                  </Typography>
                  <input
                    hidden
                    type={'file'}
                    multiple={true}
                    accept={'application/pdf, image/jpg, image/jpeg, image/png'}
                    name="dmpCertificates"
                    ref={inputRefDmpdmpCertificatesUpload}
                    onBlur={formik.handleBlur}
                    onChange={async (event) => {
                      if (event.target.files?.length) {
                        const files = Array.from(event?.target?.files)
                        for (const file of files) {
                          if (file.type === PDF_FILE_TYPE) {
                            const isValid = await isPDFValid(file)
                            if (!isValid) {
                              event.target.value = ''
                              formik.setFieldValue('dmpCertificates', [])
                              openErrorDialogWithMessage(
                                t(
                                  'ContractForm.Fields.PrData.common.uploadError.message',
                                  { name: file.name },
                                ),
                              )
                              return
                            }
                          }
                        }
                        formik.setFieldValue('dmpCertificates', files)
                      }
                    }}
                  />
                  <Box mt={1}>
                    <Button
                      variant="contained"
                      onClick={() =>
                        inputRefDmpdmpCertificatesUpload.current?.click()
                      }
                      size="small"
                      startIcon={<CloudUploadIcon />}
                    >
                      {t(
                        'MembershipForm.Fields.TypeOfMembership.RegionalMembershipAttachment.upload',
                      )}
                    </Button>
                  </Box>
                  <FormHelperText error={!!formik.errors.dmpCertificates}>
                    {!!formik.errors.dmpCertificates
                      ? Array.isArray(formik.errors.dmpCertificates) &&
                        formik.errors.dmpCertificates.length > 0
                        ? formik.errors.dmpCertificates[0]
                        : formik.errors.dmpCertificates
                      : formik.values.dmpCertificates
                          .map(({ name }) => name)
                          .join(', ')}
                  </FormHelperText>
                </>
              }
            </Collapse>
          </Grid>
        )}
        {/* PRAXISSOFTWARE */}
        <Grid item xs={12}>
          <Box mt={2}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h4" component="h3">
                  {t('ContractForm.Fields.PrData.Software.title')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  {t('ContractForm.Fields.PrData.Software.description')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="softwareId-label">
                    {t('ContractForm.Fields.PrData.Software.label')}
                  </InputLabel>
                  <Select
                    id="softwareId"
                    label={t('ContractForm.Fields.PrData.Software.label')}
                    labelId="softwareId-label"
                    name="softwareId"
                    onChange={formik.handleChange}
                    value={formik.values.softwareId}
                  >
                    <MenuItem value="">
                      <em>
                        {t('ContractForm.Fields.PrData.Software.default')}
                      </em>
                    </MenuItem>

                    {supportedSoftwares.map((software) => (
                      <MenuItem key={software.id} value={software.id}>
                        {software.name} ({software.manufacturer})
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {incompatibleContracts.length > 0 && (
                  <Box display="flex" alignItems="center" mt={2}>
                    <Box mr={1}>
                      <ErrorIcon color="error" />
                    </Box>
                    <Typography variant="body2">
                      {t(
                        'ContractForm.Fields.PrData.Software.incompatibilityHint',
                        {
                          contracts: incompatibleContracts.join(', '),
                        },
                      )}
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
        </Grid>
        {/* VERAH */}
        <Box mt={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={showVerah}
                name="hasVerah"
                onBlur={formik.handleBlur}
                onChange={({ target }) => {
                  setShowVerah(!showVerah)
                  handleShowVerahChange(target.checked)
                }}
                value={showVerah}
              />
            }
            label={
              <Box display="flex" alignItems="center">
                <Typography
                  component="h3"
                  style={{
                    display: 'flex',
                    lineHeight: 'inherit',
                  }}
                  variant="h4"
                >
                  <Box mr={1}>
                    {t('ContractForm.Fields.PrData.Verah.label')}
                  </Box>
                </Typography>
                <Tooltip
                  arrow
                  enterTouchDelay={100}
                  interactive
                  title={
                    <Trans
                      i18nKey="ContractForm.Fields.PrData.Verah.tooltip"
                      components={{
                        linkTag: (
                          <Link
                            className="text-white"
                            rel="noreferrer"
                            target="_blank"
                            to={{
                              pathname: 'https://www.verah.de',
                            }}
                          />
                        ),
                      }}
                    />
                  }
                >
                  <InfoIcon color="primary" />
                </Tooltip>
              </Box>
            }
          />
        </Box>

        <FieldArray name="verahCoworkers">
          {({ remove, push }) => {
            const coworkers = formik.values.verahCoworkers as VerahCoworker[]
            const touched = formik.touched
            const errors = formik.errors

            const isTouched = (fieldName: keyof VerahCoworker, idx: number) =>
              touched.verahCoworkers &&
              touched.verahCoworkers[idx] &&
              touched.verahCoworkers[idx][fieldName]

            const getError = (fieldName: keyof VerahCoworker, idx: number) => {
              const coworkersErrors = errors.verahCoworkers as FormikErrors<
                VerahCoworker[]
              >
              return coworkersErrors?.[idx]?.[fieldName]
            }

            return (
              showVerah && (
                <>
                  {coworkers.length > 0 &&
                    coworkers.map((verah, index) => (
                      <React.Fragment key={index}>
                        <Grid item xs={12}>
                          <Box display="flex" alignItems="center">
                            <Typography variant="body2" component="h4">
                              {t('ContractForm.verah')} #{index + 1}
                            </Typography>
                            {coworkers.length === index + 1 && index !== 0 && (
                              <Box ml={1}>
                                <Button
                                  onClick={() => remove(index)}
                                  variant="contained"
                                  size="small"
                                >
                                  {t('ContractForm.removeVerah')}
                                </Button>
                              </Box>
                            )}{' '}
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            inputProps={{ maxLength: 100 }}
                            error={Boolean(
                              isTouched('firstName', index) &&
                                getError('firstName', index),
                            )}
                            helperText={
                              isTouched('firstName', index) &&
                              getError('firstName', index)
                            }
                            label={t(
                              'ContractForm.Fields.PrData.FirstName.label',
                            )}
                            name={`verahCoworkers.${index}.firstName`}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            required={showVerah}
                            value={verah.firstName}
                            variant="outlined"
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <TextField
                            error={Boolean(
                              isTouched('lastName', index) &&
                                getError('lastName', index),
                            )}
                            fullWidth
                            inputProps={{ maxLength: 100 }}
                            helperText={
                              isTouched('lastName', index) &&
                              getError('lastName', index)
                            }
                            label={t(
                              'ContractForm.Fields.PrData.LastName.label',
                            )}
                            name={`verahCoworkers.${index}.lastName`}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            required={showVerah}
                            value={verah.lastName}
                            variant="outlined"
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <TextField
                            error={Boolean(
                              isTouched('birthName', index) &&
                                getError('birthName', index),
                            )}
                            fullWidth
                            inputProps={{ maxLength: 100 }}
                            helperText={
                              isTouched('birthName', index) &&
                              getError('birthName', index)
                            }
                            label={t(
                              'ContractForm.Fields.PrData.BirthName.label',
                            )}
                            name={`verahCoworkers.${index}.birthName`}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={verah.birthName}
                            variant="outlined"
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <KeyboardDatePicker
                            error={Boolean(
                              isTouched('dateOfBirth', index) &&
                                getError('dateOfBirth', index),
                            )}
                            helperText={
                              isTouched('dateOfBirth', index) &&
                              getError('dateOfBirth', index)
                            }
                            label={t(
                              'ContractForm.Fields.PrData.DateOfBirth.label',
                            )}
                            maxDate={maxDOB}
                            minDate={minDOB}
                            name={`verahCoworkers.${index}.dateOfBirth`}
                            onBlur={formik.handleBlur}
                            onDateChange={(value) =>
                              formik.setFieldValue(
                                `verahCoworkers.${index}.dateOfBirth`,
                                value,
                              )
                            }
                            value={verah.dateOfBirth}
                            required
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <KeyboardDatePicker
                            error={Boolean(
                              isTouched('activeSince', index) &&
                                getError('activeSince', index),
                            )}
                            helperText={
                              isTouched('activeSince', index) &&
                              getError('activeSince', index)
                            }
                            label={t(
                              'ContractForm.Fields.PrData.ActiveSince.label',
                            )}
                            name={`verahCoworkers.${index}.activeSince`}
                            onBlur={formik.handleBlur}
                            onDateChange={(value) =>
                              formik.setFieldValue(
                                `verahCoworkers.${index}.activeSince`,
                                value,
                              )
                            }
                            required
                            value={verah.activeSince}
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <FormControl
                            error={Boolean(
                              isTouched('weeklyHours', index) &&
                                getError('weeklyHours', index),
                            )}
                            fullWidth
                            required
                            variant="outlined"
                          >
                            <InputLabel id="verah-weekly-hours-label">
                              {t(
                                'ContractForm.Fields.PrData.WeeklyHours.label',
                              )}
                            </InputLabel>

                            <Select
                              error={Boolean(
                                isTouched('weeklyHours', index) &&
                                  getError('weeklyHours', index),
                              )}
                              id="weeklyHours"
                              labelId="verah-weekly-hours-label"
                              label={t(
                                'ContractForm.Fields.PrData.WeeklyHours.label',
                              )}
                              name={`verahCoworkers.${index}.weeklyHours`}
                              onChange={formik.handleChange}
                              value={verah.weeklyHours}
                            >
                              <MenuItem value="">
                                <em>
                                  {t(
                                    'ContractForm.Fields.PrData.WeeklyHours.default',
                                  )}
                                </em>
                              </MenuItem>

                              {verahWeeklyWorkTime &&
                                verahWeeklyWorkTime.map((option, index) => (
                                  <MenuItem key={index} value={option}>
                                    {option}
                                  </MenuItem>
                                ))}
                            </Select>

                            {isTouched('weeklyHours', index) &&
                              getError('weeklyHours', index) && (
                                <FormHelperText>
                                  {getError('weeklyHours', index)}
                                </FormHelperText>
                              )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography component="span">
                            <Box>
                              {t('ContractForm.verahWorkingHoursHasChanged')}
                            </Box>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControl
                            error={Boolean(
                              isTouched('weeklyHoursNew', index) &&
                                getError('weeklyHoursNew', index),
                            )}
                            fullWidth
                            variant="outlined"
                          >
                            <InputLabel id="verah-weekly-hours-changed-label">
                              {t(
                                'ContractForm.Fields.PrData.WeeklyHoursNew.label',
                              )}
                            </InputLabel>

                            <Select
                              id="weeklyHoursNew"
                              label={t(
                                'ContractForm.Fields.PrData.WeeklyHoursNew.label',
                              )}
                              labelId="verah-weekly-hours-changed-label"
                              name={`verahCoworkers.${index}.weeklyHoursNew`}
                              onChange={formik.handleChange}
                              required={!!verah.weeklyHoursChangedSince}
                              value={verah.weeklyHoursNew}
                            >
                              <MenuItem value="">
                                <em>
                                  {t(
                                    'ContractForm.Fields.PrData.WeeklyHoursNew.default',
                                  )}
                                </em>
                              </MenuItem>

                              {verahWeeklyWorkTime &&
                                verahWeeklyWorkTime.map((option, index) => (
                                  <MenuItem key={index} value={option}>
                                    {option}
                                  </MenuItem>
                                ))}
                            </Select>

                            {isTouched('weeklyHoursNew', index) &&
                              getError('weeklyHoursNew', index) && (
                                <FormHelperText>
                                  {getError('weeklyHoursNew', index)}
                                </FormHelperText>
                              )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <KeyboardDatePicker
                            autoOk
                            error={Boolean(
                              isTouched('weeklyHoursChangedSince', index) &&
                                getError('weeklyHoursChangedSince', index),
                            )}
                            helperText={
                              isTouched('weeklyHoursChangedSince', index) &&
                              getError('weeklyHoursChangedSince', index)
                            }
                            label={t(
                              'ContractForm.Fields.PrData.WeeklyHoursChangedSince.label',
                            )}
                            name={`verahCoworkers.${index}.weeklyHoursChangedSince`}
                            onBlur={formik.handleBlur}
                            onDateChange={(value) =>
                              formik.setFieldValue(
                                `verahCoworkers.${index}.weeklyHoursChangedSince`,
                                value,
                              )
                            }
                            openTo="year"
                            value={verah.weeklyHoursChangedSince}
                          />
                        </Grid>
                      </React.Fragment>
                    ))}
                  {formik.values.verahCoworkers.length < 3 && (
                    <Grid item xs={12} md={6}>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() =>
                          push({
                            activeSince: null,
                            birthName: '',
                            dateOfBirth: null,
                            firstName: '',
                            lastName: '',
                            weeklyHours: '',
                            weeklyHoursChangedSince: null,
                            weeklyHoursNew: '',
                          })
                        }
                      >
                        {t('ContractForm.addVerah')}
                      </Button>
                    </Grid>
                  )}
                </>
              )
            )
          }}
        </FieldArray>

        {/* SONSTIGE VORAUSSETZUNGEN */}
        {additionalAttributes.length > 0 && (
          <Grid item xs={12}>
            <Typography variant="h4" component="h3">
              <Box fontWeight={'fontWeightBold'} mt={2}>
                {t('ContractForm.Fields.PrData.additionalAttributes.label')}
              </Box>
            </Typography>
            {additionalAttributes.map((attribute, index) => {
              const errors: { [key: string]: any } = formik.errors
              return (
                <Grid key={attribute.id} item xs={12}>
                  <FormControl
                    variant="outlined"
                    required={attribute.isRequired}
                    error={Boolean(
                      errors[`additionalAttributeIds_${attribute.id}`],
                    )}
                    className={
                      errors[`additionalAttributeIds_${attribute.id}`]
                        ? classes.checkboxError
                        : undefined
                    }
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.additionalAttributeIds.includes(
                              attribute.id.toString(),
                            )}
                            name={`additionalAttributeIds_${attribute.id}`}
                            onBlur={formik.handleBlur}
                            onChange={() => {
                              const currentList =
                                formik.values.additionalAttributeIds
                              formik.setFieldValue(
                                `additionalAttributeIds`,
                                currentList.includes(attribute.id.toString())
                                  ? currentList.filter(
                                      (item) =>
                                        item !== attribute.id.toString(),
                                    )
                                  : [...currentList, attribute.id.toString()],
                              )
                            }}
                          />
                        }
                        key={attribute.id}
                        label={
                          <Box display="flex" alignItems="center">
                            <Typography
                              component="span"
                              style={{ display: 'flex', lineHeight: 'inherit' }}
                              variant="h4"
                            >
                              {attribute.title}
                            </Typography>
                            <Box ml={1}>
                              {attribute.informationText && (
                                <Tooltip
                                  arrow
                                  title={
                                    t(
                                      'ContractForm.Fields.PrData.additionalAttributeIds.hint',
                                    ) || ''
                                  }
                                  placement={'right'}
                                >
                                  <IconButton
                                    size="small"
                                    color="primary"
                                    className={classes.hintIconButton}
                                    onClick={() =>
                                      setOpenModalWith({
                                        content: (
                                          <Markdown linkTarget={'_blank'}>
                                            {attribute.informationText}
                                          </Markdown>
                                        ),
                                        title: attribute.title,
                                      })
                                    }
                                  >
                                    <InfoIcon />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </Box>
                          </Box>
                        }
                      />
                    </FormGroup>
                  </FormControl>
                  {Boolean(
                    errors[`additionalAttributeIds_${attribute.id}`],
                  ) && (
                    <FormHelperText error={true}>
                      {t(
                        'ContractForm.Fields.PrData.additionalAttributes.required',
                      )}
                    </FormHelperText>
                  )}
                  {attribute.hasUpload && (
                    <Grid item xs={12} md={12}>
                      <Collapse
                        in={formik.values.additionalAttributeIds.includes(
                          attribute.id.toString(),
                        )}
                        onExiting={() => {
                          inputRefsAdditionalAttributes[index].current!.value =
                            ''
                          formik.setFieldValue(
                            `certificates.${index + allQualifications.length}`,
                            null,
                          )
                        }}
                      >
                        {
                          <>
                            <Typography gutterBottom>
                              {t(
                                'ContractForm.Fields.PrData.additionalAttributeIds.uploadCertificate',
                              )}
                            </Typography>
                            <input
                              hidden
                              type={'file'}
                              multiple={false}
                              accept={
                                'application/pdf, image/jpg, image/jpeg, image/png'
                              }
                              name={`certificates.${
                                index + allQualifications.length
                              }`}
                              ref={inputRefsAdditionalAttributes[index]}
                              onBlur={formik.handleBlur}
                              onChange={async (event) => {
                                if (event?.target?.files?.length) {
                                  const file = event?.target?.files[0]
                                  if (file.type === PDF_FILE_TYPE) {
                                    const isValid = await isPDFValid(file)
                                    if (!isValid) {
                                      formik.setFieldValue(
                                        `certificates.${
                                          index + allQualifications.length
                                        }`,
                                        null,
                                      )
                                      inputRefsAdditionalAttributes[
                                        index
                                      ].current!.value = ''
                                      openErrorDialogWithMessage(
                                        t(
                                          'ContractForm.Fields.PrData.common.uploadError.message',
                                          { name: file.name },
                                        ),
                                      )
                                      return
                                    }
                                  }
                                  formik.setFieldValue(
                                    `certificates.${
                                      index + allQualifications.length
                                    }`,
                                    file,
                                  )
                                }
                              }}
                            />
                            <Button
                              variant={'contained'}
                              size="small"
                              onClick={() =>
                                inputRefsAdditionalAttributes[index]?.current &&
                                inputRefsAdditionalAttributes[
                                  index
                                ]?.current?.click()
                              }
                            >
                              {t(
                                'MembershipForm.Fields.TypeOfMembership.RegionalMembershipAttachment.upload',
                              )}
                            </Button>
                            <FormHelperText
                              error={
                                !!formik.errors.certificates?.[
                                  index + allQualifications.length
                                ]
                              }
                            >
                              {formik.errors.certificates?.[
                                index + allQualifications.length
                              ]
                                ? formik.errors.certificates?.[
                                    index + allQualifications.length
                                  ]
                                : inputRefsAdditionalAttributes[index]?.current
                                    ?.files?.length
                                ? // @ts-ignore: Object is possibly null
                                  inputRefsAdditionalAttributes[index].current
                                    .files[0].name
                                : ''}
                            </FormHelperText>
                            <Box marginTop={2}>
                              <Typography variant={'body2'}>
                                {t(
                                  'ContractForm.Fields.PrData.additionalAttributeIds.uploadHelpText',
                                )}
                              </Typography>
                            </Box>
                          </>
                        }
                      </Collapse>
                    </Grid>
                  )}
                </Grid>
              )
            })}
          </Grid>
        )}

        {/* QUALIFIKATIONSMATRIZEN */}
        <Grid item container spacing={3}>
          {matrices.map((matrix, matrixIndex) => {
            const qualificationProviderDisplaySettings =
              settingsPerMatrix[matrixIndex]
            const matrixQualifications = matrix.rows

            return (
              <Grid item container xs={12}>
                <Grid item xs={12}>
                  <Typography variant="h4">
                    <Box fontWeight="fontWeightBold" mt={2} mb={2}>
                      {matrix.contractName ? (
                        <>
                          {t('ContractForm.qualificationsForContract')}{' '}
                          {matrix.contractName}
                        </>
                      ) : (
                        <>
                          {t('ContractForm.qualificationsInRegion')}{' '}
                          {region?.name}
                        </>
                      )}
                    </Box>
                  </Typography>
                </Grid>
                <Hidden smDown>
                  {qualificationProviderDisplaySettings.providerPersonalId && (
                    <>
                      <Grid item xs={6} />
                      <Grid item xs>
                        <Typography
                          align="center"
                          color="primary"
                          dangerouslySetInnerHTML={{
                            __html: t(
                              'ContractForm.Fields.PrData.qualificationIds.labelPersonal',
                            ),
                          }}
                        />
                      </Grid>
                    </>
                  )}

                  {qualificationProviderDisplaySettings.providerAffiliatePartnerId && (
                    <Grid item xs>
                      <Typography
                        align="center"
                        color="primary"
                        dangerouslySetInnerHTML={{
                          __html: t(
                            'ContractForm.Fields.PrData.qualificationIds.labelAffiliatePartner',
                          ),
                        }}
                      />
                    </Grid>
                  )}

                  {qualificationProviderDisplaySettings.providerDeviceAssociationId && (
                    <Grid item xs>
                      <Typography
                        align="center"
                        color="primary"
                        dangerouslySetInnerHTML={{
                          __html: t(
                            'ContractForm.Fields.PrData.qualificationIds.labelDeviceAssociation',
                          ),
                        }}
                      />
                    </Grid>
                  )}
                </Hidden>
                {matrixQualifications.map(
                  (qualification, _qualificationIndex) => {
                    // get total row index across all matrices
                    const index = matrices.reduce((acc, next, index) => {
                      if (index < matrixIndex) {
                        acc += next.rows.length
                      }
                      if (index === matrixIndex) {
                        acc += _qualificationIndex
                      }
                      return acc
                    }, 0)

                    return (
                      (qualification.providerPersonalId ||
                        qualification.providerAffiliatePartnerId ||
                        qualification.providerDeviceAssociationId) && (
                        <Grid
                          className={classes.checkboxRow}
                          item
                          key={qualification.label}
                          xs={12}
                        >
                          <FormControl
                            className={classes.checkboxRowFormControl}
                            component={Grid}
                            container
                          >
                            <Grid
                              container
                              alignContent="center"
                              alignItems="center"
                              item
                              md={6}
                            >
                              <FormLabel>
                                <Typography
                                  component="span"
                                  color="textPrimary"
                                  variant="h4"
                                >
                                  {qualification.label}
                                </Typography>
                              </FormLabel>
                              {qualification.helpText && (
                                <Tooltip
                                  arrow
                                  title={
                                    t(
                                      'ContractForm.Fields.PrData.qualificationIds.hint',
                                    ) || ''
                                  }
                                  placement={'right'}
                                >
                                  <IconButton
                                    size="small"
                                    color="default"
                                    className={classes.hintIconButton}
                                    onClick={() =>
                                      setOpenModalWith({
                                        content: (
                                          <Markdown linkTarget={'_blank'}>
                                            {qualification.helpText}
                                          </Markdown>
                                        ),
                                        title: qualification.label,
                                      })
                                    }
                                  >
                                    <InfoIcon />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </Grid>

                            {qualificationProviderDisplaySettings.providerPersonalId && (
                              <Grid
                                className={classes.checkboxRowLabel}
                                item
                                md
                              >
                                {qualification.providerPersonalId && (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={formik.values.qualificationIds.includes(
                                          `${qualification.providerPersonalId}`,
                                        )}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        name="qualificationIds"
                                        value={qualification.providerPersonalId}
                                      />
                                    }
                                    label={
                                      mdUp
                                        ? ''
                                        : t(
                                            'ContractForm.Fields.PrData.qualificationIds.labelPersonal',
                                          )
                                    }
                                  />
                                )}
                              </Grid>
                            )}

                            {qualificationProviderDisplaySettings.providerAffiliatePartnerId && (
                              <Grid
                                className={classes.checkboxRowLabel}
                                item
                                md
                              >
                                {qualification.providerAffiliatePartnerId && (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={formik.values.qualificationIds.includes(
                                          `${qualification.providerAffiliatePartnerId}`,
                                        )}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        name="qualificationIds"
                                        value={
                                          qualification.providerAffiliatePartnerId
                                        }
                                      />
                                    }
                                    label={
                                      mdUp ? (
                                        ''
                                      ) : (
                                        <Typography
                                          component="span"
                                          dangerouslySetInnerHTML={{
                                            __html: t(
                                              'ContractForm.Fields.PrData.qualificationIds.labelAffiliatePartner',
                                            ),
                                          }}
                                        />
                                      )
                                    }
                                  />
                                )}
                              </Grid>
                            )}

                            {qualificationProviderDisplaySettings.providerDeviceAssociationId && (
                              <Grid
                                className={classes.checkboxRowLabel}
                                item
                                md
                              >
                                {qualification.providerDeviceAssociationId && (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={formik.values.qualificationIds.includes(
                                          `${qualification.providerDeviceAssociationId}`,
                                        )}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        name="qualificationIds"
                                        value={
                                          qualification.providerDeviceAssociationId
                                        }
                                      />
                                    }
                                    label={
                                      mdUp ? (
                                        ''
                                      ) : (
                                        <Typography
                                          component="span"
                                          dangerouslySetInnerHTML={{
                                            __html: t(
                                              'ContractForm.Fields.PrData.qualificationIds.labelDeviceAssociation',
                                            ),
                                          }}
                                        />
                                      )
                                    }
                                  />
                                )}
                              </Grid>
                            )}
                            {qualification.isCertificateRequired && (
                              <Grid item xs={12} md={12}>
                                <Collapse
                                  in={isQualificationSelected(
                                    qualification,
                                    formik.values.qualificationIds,
                                  )}
                                  onExiting={() => {
                                    inputRefsQualifications[
                                      index
                                    ].current!.value = ''
                                    formik.setFieldValue(
                                      `certificates.${index}`,
                                      null,
                                    )
                                  }}
                                >
                                  {
                                    <>
                                      <Typography gutterBottom>
                                        {t(
                                          'ContractForm.Fields.PrData.qualificationIds.uploadCertificate',
                                        )}
                                      </Typography>
                                      <input
                                        hidden
                                        type={'file'}
                                        multiple={false}
                                        accept={
                                          'application/pdf, image/jpg, image/jpeg, image/png'
                                        }
                                        name={`certificates.${index}`}
                                        ref={inputRefsQualifications[index]}
                                        onBlur={formik.handleBlur}
                                        onChange={async (event) => {
                                          if (event?.target?.files?.length) {
                                            const file = event?.target?.files[0]
                                            if (file.type === PDF_FILE_TYPE) {
                                              const isValid = await isPDFValid(
                                                file,
                                              )
                                              if (!isValid) {
                                                formik.setFieldValue(
                                                  `certificates.${index}`,
                                                  null,
                                                )
                                                inputRefsQualifications[
                                                  index
                                                ].current!.value = ''
                                                openErrorDialogWithMessage(
                                                  t(
                                                    'ContractForm.Fields.PrData.common.uploadError.message',
                                                    { name: file.name },
                                                  ),
                                                )
                                                return
                                              }
                                            }

                                            formik.setFieldValue(
                                              `certificates.${index}`,
                                              file,
                                            )
                                          }
                                          event?.target?.files?.length &&
                                            formik.setFieldValue(
                                              `certificates.${index}`,
                                              event?.target?.files[0],
                                            )
                                        }}
                                      />
                                      <Button
                                        variant={'contained'}
                                        color={'default'}
                                        startIcon={<CloudUploadIcon />}
                                        onClick={() =>
                                          inputRefsQualifications[index]
                                            ?.current &&
                                          inputRefsQualifications[
                                            index
                                          ]?.current?.click()
                                        }
                                      >
                                        {t(
                                          'MembershipForm.Fields.TypeOfMembership.RegionalMembershipAttachment.upload',
                                        )}
                                      </Button>
                                      <FormHelperText
                                        error={
                                          !!formik.errors.certificates?.[index]
                                        }
                                      >
                                        {formik.errors.certificates?.[index]
                                          ? formik.errors.certificates?.[index]
                                          : inputRefsQualifications[index]
                                              ?.current?.files?.length
                                          ? // @ts-ignore: Object is possibly null
                                            inputRefsQualifications[index]
                                              .current.files[0].name
                                          : ''}
                                      </FormHelperText>
                                      <Box marginTop={2}>
                                        <Typography variant={'body2'}>
                                          {t(
                                            'ContractForm.Fields.PrData.qualificationIds.uploadHelpText',
                                          )}
                                        </Typography>
                                      </Box>
                                    </>
                                  }
                                </Collapse>
                              </Grid>
                            )}
                            {qualification.isKimEmailRequired && (
                              <Grid item xs={12} md={6}>
                                <Collapse
                                  in={isQualificationSelected(
                                    qualification,
                                    formik.values.qualificationIds,
                                  )}
                                  onExiting={() => {
                                    formik.setFieldValue(
                                      `kimAddresses.${index}`,
                                      null, // "remove" the entry from kimAddresses[]
                                    )
                                  }}
                                  onEntered={() => {
                                    formik.setFieldValue(
                                      `kimAddresses.${index}`,
                                      '', // mark the entry as required
                                    )
                                  }}
                                >
                                  {
                                    <Box marginY={3}>
                                      <TextField
                                        error={Boolean(
                                          // @ts-ignore
                                          formik.touched.kimAddresses?.[
                                            index
                                          ] &&
                                            formik.errors.kimAddresses?.[index],
                                        )}
                                        fullWidth
                                        inputProps={{ maxLength: 255 }}
                                        helperText={
                                          // @ts-ignore
                                          formik.touched.kimAddresses?.[
                                            index
                                          ] &&
                                          formik.errors.kimAddresses?.[index]
                                        }
                                        label={t(
                                          'ContractForm.Fields.PrData.qualificationIds.kimAddress',
                                        )}
                                        name={`kimAddresses.${index}`}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        required
                                        value={
                                          formik.values.kimAddresses[index] ||
                                          ''
                                        }
                                        variant="outlined"
                                      />
                                      <FormHelperText>
                                        {t(
                                          'ContractForm.Fields.PrData.qualificationIds.kimAddressHelpText',
                                        )}
                                      </FormHelperText>
                                    </Box>
                                  }
                                </Collapse>
                              </Grid>
                            )}
                            {qualification.optionsList.length > 0 && (
                              <Grid item xs={12} md={12}>
                                <Collapse
                                  in={isQualificationSelected(
                                    qualification,
                                    formik.values.qualificationIds,
                                  )}
                                  onExiting={() => {
                                    formik.setFieldValue(
                                      `qualificationAnswers.${index}`,
                                      null, // mark entry as NOT required
                                    )
                                    formik.setFieldValue(
                                      `qualificationCustomAnswers.${index}`,
                                      null, // mark entry as NOT required
                                    )
                                  }}
                                  onEntered={() => {
                                    formik.setFieldValue(
                                      `qualificationAnswers.${index}`,
                                      '', // mark the entry as required
                                    )
                                  }}
                                >
                                  {
                                    <Box marginY={3}>
                                      <Grid container spacing={2}>
                                        <Grid item xs={12} md={6}>
                                          <TextField
                                            error={Boolean(
                                              // @ts-ignore
                                              formik.touched
                                                .qualificationAnswers?.[
                                                index
                                              ] &&
                                                formik.errors
                                                  .qualificationAnswers?.[
                                                  index
                                                ],
                                            )}
                                            fullWidth
                                            inputProps={{ maxLength: 255 }}
                                            helperText={
                                              // @ts-ignore
                                              formik.touched
                                                .qualificationAnswers?.[
                                                index
                                              ] &&
                                              formik.errors
                                                .qualificationAnswers?.[index]
                                            }
                                            label={t(
                                              'ContractForm.Fields.PrData.qualificationIds.answerLabel',
                                            )}
                                            name={`qualificationAnswers.${index}`}
                                            onBlur={formik.handleBlur}
                                            onChange={(e) => {
                                              if (e.target.value === 'OTHER') {
                                                formik.setFieldValue(
                                                  `qualificationCustomAnswers.${index}`,
                                                  '',
                                                )
                                              } else {
                                                formik.setFieldValue(
                                                  `qualificationCustomAnswers.${index}`,
                                                  null,
                                                )
                                              }
                                              formik.handleChange(e)
                                            }}
                                            required
                                            value={
                                              formik.values
                                                .qualificationAnswers[index] ||
                                              ''
                                            }
                                            variant="outlined"
                                            select
                                          >
                                            {qualification.optionsList.map(
                                              (option) => (
                                                <MenuItem
                                                  key={option.externalId}
                                                  value={option.value}
                                                >
                                                  {option.value}
                                                </MenuItem>
                                              ),
                                            )}
                                            <MenuItem value={'OTHER'}>
                                              {t(
                                                'ContractForm.Fields.PrData.qualificationIds.customAnswerOption',
                                              )}
                                            </MenuItem>
                                          </TextField>
                                        </Grid>
                                        {formik.values.qualificationAnswers[
                                          index
                                        ] === 'OTHER' && (
                                          <Grid item xs={12} md={6}>
                                            <TextField
                                              error={Boolean(
                                                // @ts-ignore
                                                formik.touched
                                                  .qualificationCustomAnswers?.[
                                                  index
                                                ] &&
                                                  formik.errors
                                                    .qualificationCustomAnswers?.[
                                                    index
                                                  ],
                                              )}
                                              fullWidth
                                              inputProps={{ maxLength: 255 }}
                                              helperText={
                                                // @ts-ignore
                                                formik.touched
                                                  .qualificationCustomAnswers?.[
                                                  index
                                                ] &&
                                                formik.errors
                                                  .qualificationCustomAnswers?.[
                                                  index
                                                ]
                                              }
                                              label={t(
                                                'ContractForm.Fields.PrData.qualificationIds.customAnswerLabel',
                                              )}
                                              name={`qualificationCustomAnswers.${index}`}
                                              onBlur={formik.handleBlur}
                                              onChange={formik.handleChange}
                                              required
                                              value={
                                                formik.values
                                                  .qualificationCustomAnswers[
                                                  index
                                                ] || ''
                                              }
                                              variant="outlined"
                                            />
                                          </Grid>
                                        )}
                                      </Grid>
                                    </Box>
                                  }
                                </Collapse>
                              </Grid>
                            )}
                          </FormControl>
                        </Grid>
                      )
                    )
                  },
                )}
              </Grid>
            )
          })}
        </Grid>

        <PanelSubmitRow />
      </Grid>
      <Dialog
        classes={{ paper: clsx(classes.dialogRoot) }}
        open={!!openModalWith}
        onClose={() => setOpenModalWith(null)}
        maxWidth="lg"
        fullScreen={!smUp}
        fullWidth
      >
        <DialogTitle>
          <Grid container>
            <Grid item xs={12}>
              <IconButton
                onClick={() => setOpenModalWith(null)}
                className={clsx(classes.modalCloseButton)}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <Typography color="primary" variant="h2">
                {openModalWith?.title}
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>{openModalWith?.content}</DialogContent>
      </Dialog>
      <ErrorDialog
        onClose={() => openErrorDialogWithMessage('')}
        open={!!dialogErrorMessage}
        title={t('ContractForm.Fields.PrData.common.uploadError.title')}
        errorMessage={dialogErrorMessage}
      />
    </fieldset>
  )
}

export default PanelParticipationReq
