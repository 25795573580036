import type { FC } from 'react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink as RouterLink } from 'react-router-dom'
import { Box, Grid, Typography } from '@material-ui/core'

import routes from 'src/routes'
import { TestIds } from 'src/testIds'
import useUserData from 'src/hooks/useUserDate'
import Button from 'src/components/Button/Button'
import ContentStripe from 'src/components/ContentStripe/ContentStripe'
import CustomIcon from 'src/components/CustomIcon/CustomIcon'
import Page from 'src/components/Page/Page'

import useStyles from './HomeView.styles'

const HomeTestIds = TestIds.views.home

const HomeView: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { hasMembershipStatus } = useUserData()
  return (
    <Page
      className={classes.root}
      data-test-id={HomeTestIds.pageWrapper}
      title={t('Views.Home.title')}
    >
      <ContentStripe data-test-id={HomeTestIds.heroTeaser} fullHeight>
        <Grid
          alignContent="center"
          alignItems="center"
          container
          justifyContent="center"
          spacing={2}
        >
          <Grid item sm={10} md={8}>
            <Box mb={4}>
              <Typography align="center" component="h1" variant="subtitle1">
                {t('Views.Home.header')}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.buttonContainer}>
              {!hasMembershipStatus && (
                <Button
                  color="primary"
                  component={RouterLink}
                  exact
                  size="large"
                  to={routes.root.routes!.membership.path}
                  variant="contained"
                  startIcon={
                    <CustomIcon
                      iconName={'mitgliedWerden'}
                      color={'white'}
                      style={{ fontSize: '30px', fontWeight: 'normal' }}
                    />
                  }
                  data-test-id={HomeTestIds.membershipButton}
                >
                  {t('Views.Home.buttonMembership')}
                </Button>
              )}
              <Button
                color="accent"
                component={RouterLink}
                exact
                size="large"
                to={routes.root.routes!.contracts.path}
                variant="contained"
                startIcon={
                  <CustomIcon
                    iconName={'hzvTeilnahme'}
                    color={'secondary'}
                    style={{ fontSize: '34px', fontWeight: 'normal' }}
                  />
                }
                data-test-id={HomeTestIds.contractsButton}
              >
                {t('Views.Home.buttonContracts')}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </ContentStripe>
    </Page>
  )
}

export default HomeView
